import AwsS3 from '@uppy/aws-s3';
import Uppy from '@uppy/core';
import getSpeed from '@uppy/utils/lib/getSpeed';

import AwsS3MultipartUploadHelper from './aws_s3_multipart_upload_helper';

class ResumableUpload {
  constructor(options = {}) {
    const uploadInfoRequestOptions = options.uploadInfoRequest || {};

    this.options = {
      debug: false,
      uploadInfoEndpoint: options.uploadInfoEndpoint || null,
      uploadInfoData: options.uploadInfoData || {},

      retryTimeout: options.retryTimeout || 5000,
      awsS3MultipartExpires: options.awsS3MultipartExpires || 2 * 60 * 60,
      awsS3MultipartLimit: options.awsS3MultipartLimit || 10,

      uploadInfoRequest: {
        noCsrfTokenHeader: uploadInfoRequestOptions.noCsrfTokenHeader || false,
        method: uploadInfoRequestOptions.method || 'POST',
        credentials: uploadInfoRequestOptions.credentials || 'include',
      },
    };

    this.props = {
      service: null,
      retryTimeouts: new Map(),
    };

    this.fileMetaPromises = new Map();
    this.pendingUploads = [];
    this.uploadProgress = {};

    this.callbacks = new Map([
      ['initialized', new Set()],
      ['error', new Set()],

      ['uploadAdded', new Set()],
      ['uploadStarted', new Set()],
      ['uploadProgress', new Set()],
      ['uploadComplete', new Set()],
      ['uploadStalled', new Set()],
      ['uploadError', new Set()],
      ['uploadRetry', new Set()],

      ['totalProgress', new Set()],
      ['totalComplete', new Set()],
    ]);

    this.fileCallbacks = new Map();
  }

  on(event, callback) {
    this.callbacks.get(event).add(callback);
  }

  emit(fileId, event, ...args) {
    if (fileId) {
      this.emitFileCallbacks(fileId, event, ...args);
    }

    for (const callback of this.callbacks.get(event)) {
      callback(...args);
    }
  }

  emitFileCallbacks(fileId, event, ...args) {
    const fileCallbacks = this.fileCallbacks.get(fileId);
    if (!fileCallbacks) {
      return;
    }

    const callback = fileCallbacks[event];
    if (!callback) {
      return;
    }

    callback(...args);
  }

  initialize() {
    if (!this.options.uploadInfoEndpoint) {
      const error = new Error('No upload info endpoint set!');

      this.logError(error);
      this.emit(null, 'error', { error, errorMessage: error.message });

      return Promise.reject(error);
    }

    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    if (!this.options.uploadInfoRequest.noCsrfTokenHeader) {
      const csrfTokenMetaTag = document.querySelector("meta[name='csrf-token']");
      if (csrfTokenMetaTag) {
        headers['X-CSRF-Token'] = csrfTokenMetaTag.content;
      } else {
        console.warn('CSRF token meta tag not found!');
      }
    }

    const data = JSON.stringify(this.options.uploadInfoData);

    return fetch(this.options.uploadInfoEndpoint, {
      method: this.options.uploadInfoRequest.method,
      cache: 'no-cache',
      credentials: this.options.uploadInfoRequest.credentials,
      headers,
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: data,
    })
      .catch((error) => {
        this.logError(error);
        this.emit(null, 'error', { error, errorMessage: 'Upload server is not responding.' });

        throw new Error('Upload server is not responding.');
      })
      .then((response) =>
        response.json().catch((error) => {
          this.logError(error);
          this.emit(null, 'error', { error, errorMessage: 'Internal upload server error.' });

          throw new Error('Internal upload server error.');
        }),
      )
      .then((response) => {
        if (response.success) {
          this.initializeUppyFromUploadInfo(response.upload_info || response.data);
          return;
        }

        const errorMessages = response.errors ? response.errors : ['Server error.'];

        this.emit(null, 'error', {
          error: null,
          errorMessage: errorMessages,
        });

        throw new Error('Server error.');
      });
  }

  initializeUppyFromUploadInfo(uploadInfo) {
    this.service = uploadInfo.service;

    if (uploadInfo.service === 'aws_s3_multipart') {
      this.initializeUppyWithAwsS3Multipart(uploadInfo);
    } else {
      throw new Error(`Unsupported upload service: ${uploadInfo.service}`);
    }

    this.setupUppyEventHandlers();

    this.emit(null, 'initialized', { service: uploadInfo.service });
  }

  initializeUppyWithAwsS3Multipart(uploadInfo) {
    this.awsS3MultipartHelper = new AwsS3MultipartUploadHelper({
      endpoint: uploadInfo.options.endpoint,
      region: uploadInfo.options.region,
      bucket: uploadInfo.options.bucket,
      acl: uploadInfo.options.acl,
      prefix: uploadInfo.options.prefix,
      forcePathStyle: uploadInfo.options.force_path_style,
      serviceAlias: uploadInfo.options.service_alias,
      credentials: {
        accessKeyId: uploadInfo.options.credentials.access_key_id,
        secretAccessKey: uploadInfo.options.credentials.secret_access_key,
        sessionToken: uploadInfo.options.credentials.session_token,
      },
      expires: this.options.awsS3MultipartExpires,
    });

    const awsS3Options = {
      allowedMetaFields: [],
      limit: this.options.awsS3MultipartLimit,
      shouldUseMultipart: () => true,

      createMultipartUpload: (file) => this.awsS3MultipartHelper.createMultipartUpload(file),
      listParts: (file, { uploadId, key }) => this.awsS3MultipartHelper.listParts(file, { uploadId, key }),
      signPart: (file, { uploadId, key, partNumber, body, signal }) =>
        this.awsS3MultipartHelper.prepareUploadPart(file, { uploadId, key, partNumber, body, signal }),
      abortMultipartUpload: (file, { uploadId, key }) =>
        this.awsS3MultipartHelper.abortMultipartUpload(file, { uploadId, key }),
      completeMultipartUpload: (file, { uploadId, key, parts }) =>
        this.awsS3MultipartHelper.completeMultipartUpload(file, { uploadId, key, parts }),
    };

    this.uppy = new Uppy(this.uploadInfoToUppyOptions(uploadInfo)).use(AwsS3, awsS3Options);
  }

  startUpload() {
    return Promise.all(this.fileMetaPromises.values()).then(() => this.uppy.upload());
  }

  abortUpload() {
    return this.uppy.cancelAll();
  }

  retryUpload(fileId) {
    const retryNumber = ++this.uploadProgress[fileId].retries;
    this.logDebug('retryUpload', fileId, retryNumber, this.uploadProgress[fileId].retries);

    return this.uppy.retryUpload(fileId);
  }

  addFile(name, type, data, callbacks = null, meta = null) {
    let nameForUpload;
    if (this.awsS3MultipartHelper) {
      nameForUpload = this.awsS3MultipartHelper.keyWithPrefix(name);
    } else {
      nameForUpload = name;
    }

    const fileId = this.uppy.addFile({
      name: nameForUpload,
      type,
      data,
      source: 'Local',
      isRemote: false,
    });

    this._setFileMeta(fileId, name, meta);
    this._addFileCallbacks(fileId, callbacks);
    this._addUpload(fileId, data);

    return fileId;
  }

  _addFileCallbacks(fileId, callbacks) {
    if (callbacks) {
      this.fileCallbacks.set(fileId, callbacks);
    }
  }

  _setFileMeta(fileId, name, meta) {
    this.fileMetaPromises.set(
      fileId,
      (async () => {
        const uploadMeta = { ...(meta || {}) };
        if (this.awsS3MultipartHelper) {
          const s3Key = this.awsS3MultipartHelper.keyWithPrefix(name);
          const s3GetUrl = await this.awsS3MultipartHelper.getSignedUrl(s3Key);

          uploadMeta.s3 = {
            key: s3Key,
            getUrl: s3GetUrl,
            ...this.awsS3MultipartHelper.metaForUpload,
          };
        }

        if (Object.keys(uploadMeta).length > 0) {
          this.uppy.setFileMeta(fileId, uploadMeta);
        }
      })(),
    );
  }

  _addUpload(fileId, data) {
    this.pendingUploads.push(fileId);
    this.uploadProgress[fileId] = this.createEmptyUploadProgress(fileId, data);

    this.emit(fileId, 'uploadAdded', this.uploadProgress[fileId]);
  }

  setupUppyEventHandlers() {
    this.uppy.on('file-added', (file) => this.logDebug('file-added', file));
    this.uppy.on('file-removed', (file) => {
      this.logDebug('file-removed', file);
      this.removeRetryTimeout(file.id);
    });
    this.uppy.on('upload', (uploadID, files) => {
      this.logDebug('upload', uploadID, files);
      this.setUploadStart(files);
    });
    this.uppy.on('upload-progress', (file, progress) => this.setUploadProgress(file, progress));
    this.uppy.on('upload-success', (file, response) => {
      this.logDebug('upload-success', file, response);
      this.setUploadComplete(file, response);
    });
    this.uppy.on('complete', (result) => {
      this.logDebug('complete', result);

      if (Object.keys(this.uploadProgress).length === 0) {
        this.setTotalComplete();
      }
    });
    this.uppy.on('error', () => this.logError('error', this.uppy.getState().error));
    this.uppy.on('upload-error', (file, error, response) => {
      this.logError('upload-error', file, error, response);
      this.setUploadErrorAndRetry(file, error, response);
    });
    this.uppy.on('upload-retry', (fileId) => {
      this.logDebug('upload-retry', fileId);
      this.emit(fileId, 'uploadRetry', this.uploadProgress[fileId]);
    });
    this.uppy.on('info-visible', () => this.logDebug('info-visible', this.uppy.getState().info));
    this.uppy.on('info-hidden', () => this.logDebug('info-hidden'));
    this.uppy.on('cancel-all', () => this.logDebug('cancel-all'));
    this.uppy.on('restriction-failed', (file, error) => this.logDebug('restriction-failed', file, error));
    this.uppy.on('reset-progress', () => this.logDebug('reset-progress'));
  }

  setUploadStart(files) {
    for (const file of files) {
      const fileId = file.id;

      this.uploadProgress[fileId].started = true;
      this.uploadProgress[fileId].lastUpdateAt = window.performance.now();

      this.emit(fileId, 'uploadStarted', this.uploadProgress[fileId]);
    }
  }

  setUploadProgress(file, progress) {
    if (this.uploadProgress[file.id].error) {
      this.uploadProgress[file.id].error = false;
    }

    this.uploadProgress[file.id].bytesUploaded = progress.bytesUploaded;
    this.uploadProgress[file.id].bytesTotal = progress.bytesTotal;
    this.uploadProgress[file.id].progress = (progress.bytesUploaded / progress.bytesTotal) * 100;
    this.uploadProgress[file.id].speed = getSpeed(this.uploadProgress[file.id]);
    this.uploadProgress[file.id].lastUpdateAt = window.performance.now();

    this.emit(file.id, 'uploadProgress', this.uploadProgress[file.id]);

    this.updateTotalProgress();
  }

  setUploadComplete(file, response) {
    const pendingUploadIndex = this.pendingUploads.indexOf(file.id);
    this.pendingUploads.splice(pendingUploadIndex, 1);

    if (this.uploadProgress[file.id]) {
      this.uploadProgress[file.id].complete = true;

      this.uploadProgress[file.id].response = response;

      if (file.meta) {
        this.uploadProgress[file.id].meta = file.meta;
      }
    }

    this.fileMetaPromises.delete(file.id);

    this.emit(file.id, 'uploadComplete', this.uploadProgress[file.id]);

    this.setTotalComplete();
  }

  setTotalComplete() {
    if (this.pendingUploads.length === 0) {
      this.emit(null, 'totalComplete', this.uploadProgress);
    }
  }

  // eslint-disable-next-line no-unused-vars
  setUploadErrorAndRetry(file, error, response) {
    let retry = true;
    let errorMessage;
    let errorType = 'error';

    /* eslint-disable max-len */
    if (error.code === 'ExpiredToken') {
      retry = false;
      errorMessage = 'Your upload session expired. Reload the page and start your upload again.';
    } else if (error.code === 'NoSuchUpload' || error.code === 'InvalidAccessKeyId' || error.code === 'AccessDenied') {
      retry = false;
      errorMessage = 'Amazon S3 upload error occurred. Reload the page and start your upload again.';
    } else if (error.code === 'RequestTimeTooSkewed') {
      retry = false;
      errorMessage = 'Check your computer time if it is set properly. Then start your upload again.';
    } else if (error.code === 'NetworkingError' || (error.code === undefined && error.message === 'Unknown error')) {
      errorMessage =
        'Uploading failed due to a network error. Check your Internet connection and firewall. We will automatically retry uploading your files.';
      errorType = 'warning';
    } else if (error.code === 'TimeoutError') {
      errorMessage =
        'Uploading timed out. This can be caused by poor Internet connection. Please wait while we automatically retry uploading your files.';
      errorType = 'warning';
    } else if (error.code === 'AwsS3/Multipart') {
      errorMessage =
        'Amazon S3 upload error occurred. We will automatically retry uploading your files. If problem persists reload the page and start your upload again.';
    } else {
      errorMessage =
        'Unknown upload error occurred. We will automatically retry uploading your files. If problem persists reload the page and start your upload again.';
      errorMessage += ` [${error.message}]`;
    }
    /* eslint-enable max-len */

    if (retry) {
      this.uploadProgress[file.id].error = true;
      this.uploadProgress[file.id].errorMessage = errorMessage;
      this.uploadProgress[file.id].errorType = errorType;

      this.emit(file.id, 'uploadError', this.uploadProgress[file.id]);

      if (this.options.retryTimeout) {
        const retryTimeout = setTimeout(() => {
          this.removeRetryTimeout(file.id);
          this.retryUpload(file.id).catch(() => {});
        }, this.options.retryTimeout);

        this.addRetryTimeout(file.id, retryTimeout);
      }
    } else {
      this.uppy.cancelAll();
      this.emit(null, 'error', { error, type: errorType, message: [errorMessage] });
    }
  }

  updateTotalProgress() {
    let totalSpeed = 0;
    let totalUploadedBytes = 0;
    let totalBytes = 0;

    for (const fileId of Object.keys(this.uploadProgress)) {
      const progress = this.uploadProgress[fileId];

      totalUploadedBytes += progress.bytesUploaded;
      totalBytes += progress.bytesTotal;

      if (!progress.error) {
        totalSpeed += progress.speed;

        if (!progress.complete && progress.bytesTotal > 0) {
          const diff = window.performance.now() - progress.lastUpdateAt;

          if (diff > 60000) {
            this.emit(fileId, 'uploadStalled', progress);
          } else if (progress.bytesUploaded === progress.bytesTotal && diff > 30000) {
            this.emit(fileId, 'uploadStalled', progress);
          }
        }
      }
    }

    const totalProgress = {
      pendingFiles: this.pendingUploads.length,
      totalFiles: Object.keys(this.uploadProgress).length,
      totalSpeed,
      totalUploadedBytes,
      totalBytes,
      totalProgress: (totalUploadedBytes / totalBytes) * 100,
      timeRemaining: (totalBytes - totalUploadedBytes) / totalSpeed,
    };

    this.emit(null, 'totalProgress', totalProgress);
  }

  // eslint-disable-next-line no-unused-vars
  uploadInfoToUppyOptions(uploadInfo) {
    return {
      debug: this.options.debug,
      meta: {},
    };
  }

  createEmptyUploadProgress(fileId, data) {
    return {
      fileId,
      uploadStarted: new Date(),
      bytesUploaded: 0,
      bytesTotal: data.size || 0,
      progress: 0,
      speed: 0,
      retries: 0,
      lastUpdateAt: window.performance.now(),
      started: false,
      complete: false,
      error: false,
      errorMessage: '',
    };
  }

  addRetryTimeout(fileId, retryTimeout) {
    this.props.retryTimeouts.set(fileId, retryTimeout);
  }

  removeRetryTimeout(fileId) {
    if (!this.props.retryTimeouts.has(fileId)) return;

    clearTimeout(this.props.retryTimeouts.get(fileId));
    this.props.retryTimeouts.delete(fileId);
  }

  clearAllRetryTimeouts() {
    for (const timeout of this.props.retryTimeouts.values()) {
      clearTimeout(timeout);
    }

    this.props.retryTimeouts.clear();
  }

  logError(...args) {
    console.warn(args);
  }

  logDebug(...args) {
    if (!this.options.debug) return;

    // eslint-disable-next-line no-console
    console.log(...args);
  }

  get initialized() {
    return !!this.uppy;
  }

  get service() {
    return this.props.service;
  }

  set service(service) {
    this.props.service = service;
  }
}

export default ResumableUpload;
